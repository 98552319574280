<template>
  <div class="form-submission-actions">
    <div class="field__subheader d-flex mb-6">
      Post-Submission Actions
    </div>

    <v-row v-if="submissionActionCount">
      <FormSubmissionAction
        v-for="(actionDetails, actionType) in submissionActions"
        :key="actionType"
        :action-details="convertActionDetails(actionDetails)"
        :action-type="actionType"
        @update="(actionDetails) => updateSubmissionAction(actionType, actionDetails)"
        @delete="deleteSubmissionAction(actionType)"
      />
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="6">
        <v-alert
          type="info"
          class="mt-2"
        >
          After the form is submitted, no further actions will be taken post-submission.
          Once the API receives the form submission, the contact data will be processed based on the selections made above.
        </v-alert>
      </v-col>
    </v-row>

    <div class="mt-5">
      <v-menu
        v-if="actions.length"
        :offset-y="true"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="elevation-0 outlined-btn"
            height="34px"
            plain
            v-bind="attrs"
            v-on="on"
            @click="$emit('click')"
          >
            <v-icon color="#2b84eb" size="16" class="mr-0">
              mdi-plus
            </v-icon>
            Add an Action
          </v-btn>
        </template>
        <OuterList
          title="Available Action Types"
          :items="actions"
          @click="addSubmissionAction"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
import FormSubmissionAction from "./FormSubmissionAction";
import OuterList from "@/sharedComponents/OuterList";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "FormSubmissionActions",
  components: {
    FormSubmissionAction,
    OuterList,
  },
  props: {
    submissionActions: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    actions() {
      return [
        { label: 'Create an Action', value: 'create-action' },
      ].filter(i => !Object.keys(this.submissionActions).includes(i.value));
    },
    submissionActionCount() {
      return Object.keys(this.submissionActions).length;
    },
  },
  methods: {
    convertActionDetails(actionDetails) {
      if (Array.isArray(actionDetails ?? [])) {
        return {};
      }

      return actionDetails;
    },
    addSubmissionAction(selected) {
      const copy = cloneDeep(this.submissionActions);

      copy[selected.value] = {};

      this.$emit('update', copy);
    },
    updateSubmissionAction(actionType, actionDetails) {
      const copy = cloneDeep(this.submissionActions);

      copy[actionType] = actionDetails;

      this.$emit('update', copy);
    },
    deleteSubmissionAction(actionType) {
      const copy = cloneDeep(this.submissionActions);

      delete copy[actionType];

      this.$emit('update', copy);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
