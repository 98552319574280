<template>
  <v-col class="form-submission-action" cols="12" lg="6">
    <template v-if="actionType === 'create-action'">
      <h5 class="mb-3">
        Create a Form Submission Action
      </h5>
      <div class="mb-3">
        The form submission action source will be the same source selected above under "Form Submission Source".
      </div>
      <div>
        <v-btn
          class="custom-button custom-button--red-text outlined-btn outlined-btn--red px-8 mr-4"
          height="34px"
          depressed
          @click="$emit('delete')"
        >
          Delete
        </v-btn>
      </div>
    </template>
  </v-col>
</template>

<script>
export default {
  name: "FormSubmissionAction",
  props: {
    actionType: {
      default: '',
      type: String,
    },
    actionDetails: {
      default: () => {},
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-submission-action {
  border: 1px solid #bbbbbb;
  border-radius: 4px;
}
</style>
